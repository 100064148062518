<template>
  <div class="content_block">
    <el-form :model="form" label-width="110px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="申请编号：">
            <div>{{ form.code }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApplyBack">
          <el-form-item label="还货日期：">
            <div>{{ form.returnTime | dateFormat }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="申请来源：">
            <div>{{ StockSourceEnum[form.source] }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="办理类型：">
            <div>{{ form.type && OUTINSTOCKTYPE[form.type] }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态：">
            <div>{{ form.status && STATUSOBJ[form.status] }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="申请人：">
            <div>{{ form.createUserName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="申请时间：">
            <div>{{ form.createTime && dateFormat(form.createTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备件名称：">
            <div>{{ form.sparePartName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备件编码：">
            <div>{{ form.sparePartCode }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备件类型：">
            <div>{{ form.sparePartTypeName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备件规格型号：">
            <div>{{ form.spec }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply">
          <el-form-item label="数量：">
            <div>{{ form.quantity }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApplyBack">
          <el-form-item label="还货数量：">
            <div>{{ form.quantity }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApplyBack">
          <el-form-item label="还货性质：">
            <div>{{ form.returnType=='1'?'好还':'坏还' }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isFactorySource">
          <el-form-item label="序列号：">
            <div>{{ form.serialNumbers?form.serialNumbers.join(','):'' }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="工单编号：">
            <div>{{ form.workOrderCode }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="医院名称：">
            <div>{{ form.customerName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply && isFactorySource">
          <el-form-item label="厂家发货时间：">
            <div>{{ form.manufacturerDeliveryTime | dateFormat}}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply && isFactorySource">
          <el-form-item label="厂家Order号：">
            <div>{{ form.manufacturerOrderCode }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply && isCompanySource">
          <el-form-item label="要求到货时间：">
            <div>{{ form.requiredArrivalTime | dateFormat}}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApplyBack">
          <el-form-item label="对应申领单号：">
            <div>{{ form.parentCode }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApplyBack && isCompanySource">
          <el-form-item label="还货人：">
            <div>{{ form.returnerName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply  && isCompanySource">
          <el-form-item label="联系人：">
            <div>{{ form.contacts }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isCompanySource">
          <el-form-item label="联系电话：">
            <div>{{ form.contactsPhone }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isCompanySource">
          <el-form-item label="地址：">
            <div>{{ form.contactsAddress }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6"  v-if="isFactorySource">
          <el-form-item label="快递公司：">
            <div>{{ form.expressName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isFactorySource">
          <el-form-item label="快递单号：">
            <div>{{ form.expressNumber }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isUserSource">
          <el-form-item label="目标仓库：">
            <div>{{ form.newWarehouseName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isUserSource">
          <el-form-item label="原仓库：">
            <div>{{ form.oldWarehouseName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="!isApply">
          <el-form-item label="备注：">
            <div>{{ form.remark }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="isApply && (isCompanySource || isFactorySource)">
          <el-form-item label="已还货数量：">
            <div>{{ form.returnQuantity }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="isApply">
          <el-form-item label="申请备注：">
            <div>{{ form.remark }}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.approvalUserName">
        <el-col :span="6">
          <el-form-item label="审批人：">
            <div>{{ form.approvalUserName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="审批时间：">
            <div>{{ form.approvalTime && dateFormat(form.approvalTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="审批意见：">
            <div>{{ form.approvalOpinion }}</div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.handleUserName">
        <el-col :span="6">
          <el-form-item label="办理人：">
            <div>{{ form.handleUserName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="办理时间：">
            <div>{{ form.handleTime && dateFormat(form.handleTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="办理意见：">
            <div>{{ form.opinion }}</div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <BottomPanel>
      <el-button @click="$tabs.close()">返回</el-button>
    </BottomPanel>
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import { STATUSOBJ, OUTINSTOCKTYPE, StockSourceEnum } from '../stockType.js';
import { detailStockInOutApi } from '@/api/stock.js';
import { dateFormat } from '@/utils/dateFormat.js';
import BottomPanel from '@/components/common/bottomPanel/index.vue';

export default {
  name: 'DEALDETAIL',
  components: { BottomPanel },
  props: {
    id: {
      type: String,
    },
  },
  data () {
    return {
      StockSourceEnum,
      STATUSOBJ,
      OUTINSTOCKTYPE,
      dateFormat,
      form: {},
    };
  },
  filters: {
    dateFormat (val) {
      if (!val) return '';
      return dayjs(isNaN(val) ? val : Number(val)).format('YYYY-MM-DD');
    },
  },
  computed: {
    isApplyBack () {
      return this.form.type === '2';
    },
    isApply () {
      return this.form.type === '1';
    },
    isCompanySource () {
      return this.form.source === '2';
    },
    isFactorySource () {
      return this.form.source === '3';
    },
    isUserSource () {
      return this.form.source === '1';
    },
  },
  async created () {
    const rs = await detailStockInOutApi({ id: this.id });
    if (rs.heads.code === 200) {
      this.form = { ...this.form, ...rs.body };
    }
  },
};
</script>

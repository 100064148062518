import Enum from '@/utils/Enum';
export const INBOUND_OUTBOUND_TYPE = [
  {
    value: '1',
    label: '采购入库',
  },
  {
    value: '2',
    label: '销售出库',
  },
  {
    value: '3',
    label: '调拨入库',
  },
  {
    value: '4',
    label: '调拨出库',
  },
  {
    value: '5',
    label: '申领出库',
  },
  {
    value: '6',
    label: '退回入库',
  },
  {
    value: '7',
    label: '分配出库',
  },
];

export const STATUSOBJ = {
  1: '待办理',
  2: '已办理',
  3: '已拒绝',
  // 4: '草稿',
  5: '待审批',
  6: '已驳回',
  7: '已还货',
  8: '已审批',
};

export const OUTINSTOCKTYPE = {
  1: '备件申领',
  2: '备件退回',
};

export const StockSourceList = [
  {
    value: '1',
    name: '个人备件库',
  },
  {
    value: '2',
    name: '公司备件',
  },
  {
    value: '3',
    name: '厂家备件',
  },
];

export const StockSourceEnum = new Enum(StockSourceList);

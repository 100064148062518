import server from '../request';

// 个人仓库列表
export const getPersonalList = (data) => server.post('/xiaozi-xmb/wireless/saas/personal/page', data);

// 个人仓退回
export const backPersonalList = (data) => server.post('/xiaozi-xmb/wireless/saas/personal/back', data);

// 出入库列表
export const getStockInOutList = (data) => server.post('/xiaozi-xmb/wireless/saas/stockInOut/page', data);

// 办理出入库列表
export const handleStockInOutList = (data) => server.post('/xiaozi-xmb/wireless/saas/handle/page', data);

// 办理出入库
export const handleStockInOutApi = (data) => server.post('/xiaozi-xmb/wireless/saas/handle/handle', data);

// 审批办理出入库
export const approveStockInOutApi = (data) => server.post('/xiaozi-xmb/wireless/saas/handle/approve', data);

// 办理出入库
export const detailStockInOutApi = (data) => server.post('/xiaozi-xmb/wireless/saas/handle/detail', data);

// 备件库存列表
export const getInventoryOutList = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/page', data);

// 详情
export const getInventoryDetailApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/detail', data);

// 入库
export const stockInApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/stockIn', data);

// 有库存的备件列表
export const getSparePartList = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/sparePartList', data);

// 当前登陆人个人仓信息
export const getPersonalInventory = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/personalInventory', data);

// 当前登陆人个人仓信息
export const getPersonalWarehouseList = (data) =>
  server.post('/xiaozi-xmb/wireless/saas/inventory/personalWarehouseList', data);

// 出库
export const stockOutApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/stockOut', data);

// 分配
export const distributionApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/distribution', data);

// 申领
export const applyInventoryApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/apply', data);

// 调拨
export const allocationInventoryApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/allocation', data);

// 设置安全库存
export const setSafeStockApi = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/setSafeStock', data);

// 有库存的仓库列表
export const inStockWarehouseList = (data) => server.post('/xiaozi-xmb/wireless/saas/inventory/warehouseList', data);

// 管理仓库列表
export const getWarehouseList = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/page', data);

// 详情
export const getWarehouseDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/detail', data);

// 新增
export const addWarehouse = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/add', data);

// 编辑
export const editWarehouse = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/update', data);

// 仓库管理类型列表
export const getInventoryOutTypeList = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/type/page', data);

// 详情
export const getWarehouseTypeDetail = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/type/detail', data);

// 新增
export const addWarehouseType = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/type/add', data);

// 编辑
export const editWarehouseType = (data) => server.post('/xiaozi-xmb/wireless/saas/warehouse/type/update', data);

// 备件仓储设置 详情
export const detailSystemStock = (data) => server.post('/xiaozi-xmb/wireless/saas/system/detail', data);

// 备件仓储设置 更改
export const updateSystemStock = (data) => server.post('/xiaozi-xmb/wireless/saas/system/update', data);
